import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-defensive-asset-allocation/"
            }}>{`Defensive Asset Allocation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-lethargic-asset-allocation/"
            }}>{`Lethargic Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: momentum strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking equities, fixed income, and hard assets`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $10,000`}</li>
      </ul>
      <p>{`Keller’s `}<em parentName="p">{`Bold Asset Allocation`}</em>{` aims to provide a healthy, diversified asset allocation with superior protection against sudden market downturns. The strategy uses a unique canary universe to detect market turbulence and rotate from offensive assets into more defensive holdings to achieve this goal. With its monthly rebalancing schedule and docile behavior, the strategy appeals to a wide range of risk-aware investors.`}</p>
      <p>{`The strategy was developed by Wouter J. Keller, a researcher at the VU University Amsterdam and the CEO of Flex Capital, an asset management firm based in the Netherlands. He published his `}<em parentName="p">{`Bold Asset Allocation`}</em>{` in a paper released in mid-2022.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4166845"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "592px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "142.00000000000003%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADJ0lEQVRIx41WiW6jUAzs//9eQ7iPx30ToqQNSVuvZuBR2rCrjWS9E2OPx0Nevr6+BD+Mer63/tdve+9lz+H2wufnp3x8fPzVcD7fn59fHVZlKY7jiOs4Yts2DeuiKGhZlklZFJLnGdd5lkmSJNI0zY+AVodt20qW5TLdJrndJnl/f5e3t7c1gr3otxnos9VhWZaMKopCCcNQfN+XKAy553meeJ4rnu9zjixc12UGaZLKNE3PDquqFMuyeHFO1xXbssQ0zXXPti3egWlIgiCQ6+WyE2FRiGlafLvjzJdNy5Tj8UhHOMO+Zdni2Pbq2DgcpGnaZ4dd10kURZIksSgViVIxU4cFgS9pmvJcRRELFMdKiqKU8/ksj8fj2SE2h6HnA03bEuj7/c59zDE+Hnp8/KDNblHapiHwgR8QF6WURGHEwgRhIIHv82UoCs79pUCmeZS26/arbLEAzsxH1yNGDnlpcQ18+aJI8cWAI8/z/ZQvlwujBFGrqiKmmLdtw33wtO97Pny73UgVjEh5y8/V4dD35GASxxLHc0HQCYgIhnWaZRxRlGzpFJyN47iPobu0HgwVJc+AlesSNzjr+47R13XNDAAVOurJ4dB3JCse0o7ghJ3huuIuHeItWMLmwvgyDMOzQ/AJ6aLxwTmksxUGGAqQxAkxRlTadmlTV5UYhrHpBJuRIVJ7qbbuDlBFt9/RMOR02sFQk5qR5TkLEynFCNEVSZpIgsjjmBgisuv1Spvu92eHp2HgZTikpSk1T0WKqWZZSijSJGHBsAdtxF3A9eQQUSFlpHs4HBZRWETANKk6mB+PBoXCpnDM+5frdR9Dc+mU2ZEptjPLGFTm9fWV51CXw8HgCzXm972Um7pe9Q0EByX0umGXdCQ/+de2DKDrO1YcHbNTlIEYIfV8oQgKUpYF267r2rX99IjijOP5x+fhWw/bhqTWzY8oAT5FIAhIoTCclUh3DrKo62afh/gonU6nbxuGmRLTRMM5UsMHTAsD7PfH67tTxpHc07ShOis1UyVNeabnEAlQBQq1/UAxQvnP3//+i3iBnv020EAbItiuZ5vT3nv2D3NJQp6Y/7vkAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Wouter J. Keller, Relative and Absolute Momentum in Times of Rising/Low Yields: Bold Asset Allocation (BAA)",
                "title": "Wouter J. Keller, Relative and Absolute Momentum in Times of Rising/Low Yields: Bold Asset Allocation (BAA)",
                "src": "/static/447b466bd33eb83c582e81f339348427/1b853/keller-bold-asset-allocation.png",
                "srcSet": ["/static/447b466bd33eb83c582e81f339348427/5a46d/keller-bold-asset-allocation.png 300w", "/static/447b466bd33eb83c582e81f339348427/1b853/keller-bold-asset-allocation.png 592w"],
                "sizes": "(max-width: 592px) 100vw, 592px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4166845"
            }}>{`Relative and Absolute Momentum in Times of Rising/Low Yields`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=1935527"
            }}>{`Wouter J. Keller`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubsV2/Keller_BAA_v2.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p>{`Keller’s `}<em parentName="p">{`Bold Asset Allocation`}</em>{` exists in multiple flavors that differ in their universe’s composition. Our implementation of Keller’s strategy is true to the publication’s `}<em parentName="p">{`BAA-G12`}</em>{` variant. The strategy’s operation can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade an offensive universe of twelve assets covering U.S. and international equities, hard assets, long-term treasuries, corporate bonds, and high-yield bonds`}</li>
        <li parentName="ul">{`trade a defensive universe of high-quality bonds and commodities`}</li>
        <li parentName="ul">{`use a canary universe to detect market turbulence and switch between the universes`}</li>
        <li parentName="ul">{`rank the assets in each universe by momentum, and allocate capital to the top-ranking assets`}</li>
      </ul>
      <p>{`For full detail and the rationale behind `}<em parentName="p">{`Bold Asset Allocation`}</em>{`, refer to Keller’s detailed paper. Further, the C# source code for the strategy is included in the `}<em parentName="p">{`TuringTrader.org`}</em>{` open-source project repository.`}</p>
      <p>{`Keller’s strategy successfully solves a typical problem of tactical investment strategies: Reducing noise from momentum indicators while at the same time allowing the system to react to changes in market regime swiftly. Keller’s refined approach of using a canary universe to detect market regimes is highly functional yet charmingly simple. This simplicity is essential in confidently following the strategy’s asset selections without second-guessing.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Keller’s strategy spends about half of the time in the defensive universe, which contains high-quality debt and commodities. As a result, the `}<em parentName="p">{`Bold Asset Allocation`}</em>{` has a very low beta and, thanks to including absolute momentum in the defensive universe, low interest-rate risk.`}</p>
      <p>{`Keller’s strategy only enters its offensive regime briefly when circumstances appear favorable. When it does, the strategy may invest all of its capital in the stock market, albeit in indices tracking various markets. This exposure leads to significant tail risk should markets outpace the strategy’s ability to switch to its defensive regime.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Overall, the `}<em parentName="p">{`Bold Asset Allocation`}</em>{` shows very docile behavior with steady returns over the last economic cycle: `}<em parentName="p">{`BAA`}</em>{` gracefully handled the 2008 recession and excelled during the 2009 rebound, the challenging markets of 2015, and the short-lived downturn of 2020.`}</p>
      <p>{`The Monte Carlo simulation shows that Keller’s strategy offers significant value to investors: `}<em parentName="p">{`Bold Asset Allocation`}</em>{` achieves higher returns than the 60/40 benchmark while at the same time significantly reducing drawdowns and speeding up recovery.`}</p>
      <p>{`However, these advantages come at a psychological cost. Over the backtested period, `}<em parentName="p">{`Bold Asset Allocation`}</em>{` was trailing its benchmark in most years. The strategy follows a ‘win more by losing less’ approach, with its upside generated mainly during the recessions of 2008 and 2022.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`Just like other strategies rotating assets, this strategy frequently causes taxable events. The chart above shows that investors cannot expect `}<em parentName="p">{`Bold Asset
Allocation`}</em>{` to hold any asset long enough to qualify for long-term treatment of capital gains. Consequently, `}<em parentName="p">{`BAA`}</em>{` works best in tax-deferred accounts.`}</p>
      <p>{`Keller’s strategy invests in up to 9 ETFs simultaneously. Therefore, it requires an account size of no less than $10,000 to function correctly.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, November 2022`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      